import { defineStore } from 'pinia';
import { computed, onUnmounted, ref, watch } from 'vue';
import { useTimeoutPoll } from '@vueuse/core';
import { useRequestError } from '../../../composables';
import { useDownloadsApi } from '../services';
import type { Download } from '../interfaces';
import { StoreNames } from '../../../shared/store-names';

export const useDownloadsWidgetStore = defineStore(
  StoreNames.DownloadsWidget,
  () => {
    const { getDownloadsByIds } = useDownloadsApi();
    const { parseError, requestError, clearError } = useRequestError();

    const downloads = ref<Download[]>([]);
    const totalDownloads = ref<number>(0);

    const fetchingDownloads = ref(false);
    const fetched = ref(false);

    let fetchingPromise: Promise<void> | null = null;

    async function fetch(force = false) {
      if ((fetched.value && !force) || !downloads.value.length) {
        return fetchingPromise ?? Promise.resolve();
      }
      if (!fetchingPromise) {
        fetchingPromise = (async () => {
          fetchingDownloads.value = true;

          try {
            clearError();
            const { data } = await getDownloadsByIds(downloads.value.map(({ id }) => id));

            downloads.value = data.data;
          } catch (error) {
            parseError(error);
            console.error(error);
          } finally {
            fetchingDownloads.value = false;
            fetched.value = true;
            fetchingPromise = null;
          }
        })();
      }

      return fetchingPromise;
    }

    const allCompleted = computed(() =>
      downloads.value.every(({ status }) => status === 'completed'),
    );

    const allFailed = computed(() => downloads.value.every(({ status }) => status === 'failed'));

    const pendingDownloads = computed(() =>
      downloads.value.filter(({ status }) =>
        ['initializing', 'processing', 'cancelling'].includes(status),
      ),
    );

    const hasPendingItems = computed(() => pendingDownloads.value.length > 0);

    function removeDownloadById(id: string) {
      downloads.value = downloads.value.filter((download) => download.id !== id);
    }

    // Auto update
    let resumeTimeout: ReturnType<typeof setTimeout>;
    const autoUpdateInterval = ref(1000 * 5);

    const { pause, resume } = useTimeoutPoll(async () => await fetch(true), autoUpdateInterval, {
      immediate: false,
    });

    watch(
      hasPendingItems,
      (value) => {
        pause();
        clearTimeout(resumeTimeout);

        if (!value) {
          return;
        }

        resumeTimeout = setTimeout(resume, autoUpdateInterval.value);
      },
      { deep: true },
    );

    async function addDownloadById(id: string) {
      pause();

      const { data } = await getDownloadsByIds([id]);
      const download = data.data?.[0];

      if (download) {
        if (fetchingPromise) {
          await fetchingPromise;
        }
        downloads.value.unshift(download);
      }
      resume();
    }

    const panelOpen = ref(true);

    function togglePanel() {
      panelOpen.value = !panelOpen.value;
    }

    function clear() {
      downloads.value = [];
      panelOpen.value = true;
      totalDownloads.value = 0;
      clearTimeout(resumeTimeout);
    }

    onUnmounted(() => {
      clearTimeout(resumeTimeout);
    });

    return {
      hasPendingItems,
      allCompleted,
      allFailed,
      fetchingDownloads,
      fetch,
      fetched,
      requestError,
      downloads,
      clear,
      addDownloadById,
      removeDownloadById,
      togglePanel,
      panelOpen,
    };
  },
  { persist: { paths: ['downloads', 'panelOpen'] } },
);
